import React,{useEffect,useRef} from 'react';
import '../styles/components/HowCanWeHelp.scss';
import HowCanWeHelpImg from '../assets/howcanwehelp.jpg';
import astrik from '../assets/astrik.svg';
import { useLocation } from 'react-router-dom'

const HowCanWeHelp = () => {
    const location = useLocation()
    const howcanwehelpRef = useRef(null)
   

    useEffect(() => {
        if (location.state?.scrollTo === 'howcanwehelp' && howcanwehelpRef.current) {
            howcanwehelpRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        
    }, [location.state])
    return (
        <div className="HowCanWeHelp">
             <div className="HowCanWeHelpHeading" ref={howcanwehelpRef}>
                <div className="help-heading">How can we help?</div>
                <div className="help-subtext">Sent us a message!</div>
            </div>
            <div className="HowCanWeHelp-content">
                <div className="HowCanWeHelp-left">
                    <img src={HowCanWeHelpImg} alt="Feature" />
                </div>
                <div className="HowCanWeHelp-right">
                    <form>
                        <label htmlFor="name">Name <img src={astrik} alt="astrik" /></label>
                        <input type="text" id="name" name="name" placeholder="Eg: Das Hitler" />

                        <label htmlFor="email">Email <img src={astrik} alt="astrik" /></label>
                        <input type="email" id="email" name="email" placeholder="Eg: hiyou@gmail.com" />

                        <label htmlFor="message">Message<img src={astrik} alt="astrik" /></label>
                        <textarea id="message" name="message" placeholder="Type your message"></textarea>

                        <button className="HowCanWeHelp-button" type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default HowCanWeHelp;
