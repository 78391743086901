import React from 'react';
import '../styles/components/HeroSection.scss';
import { useNavigate } from "react-router-dom";
import HeroSectionImgs from '../assets/Frame3.png';
import Illustration from '../assets/Illustration1.png';
import useAnalyticsEventTracker from "../utils/useAnalyticsEventTracker.jsx";

const HeroSection = () => {
    const navigate = useNavigate();
    const gaEventTracker = useAnalyticsEventTracker("header");
    return (
        <div className="HeroSection">
            {/* { <img src={HeroSectionImgs} alt="" className="HeroSectionImg" />  } */}
            <div className="HeroSection_content-container">
            <div className="HeroSection_content-container_data-container" >
            <h1 className="HeroSectionHeading">Enhance Your Growth with Advanced Technology Solutions</h1>
            <button onClick={() => { navigate('/', { state: { scrollTo: 'howcanwehelp' } }); gaEventTracker('howcanwehelp') }}>Talk to our Expert</button></div>
           <div className="HeroSection_content-container_img-container"><img src={Illustration} alt="" /></div>
           </div>
        </div>
    );
};

export default HeroSection;