import Footer from '../../components/Footer'
import InternalHeader from '../../components/InternalHeader'
import InternalERPServices from '../../components/InternalERPServices'
import ContactUs from '../../components/ContactUs'
import HowCanWeHelp from '../../components/HowCanWeHelp'
const ERPServices = () => {
    return (
        <div className="itservices_container">
            <InternalHeader />
            <InternalERPServices />
            <ContactUs />
            {/* <HowCanWeHelp /> */}
            <Footer />
        </div>
    )
}

export default ERPServices
