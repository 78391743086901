import Footer from '../../components/Footer'
import InternalHeader from '../../components/InternalHeader'
import InternalDevelopment from '../../components/InternalDevelopment'
import ContactUs from '../../components/ContactUs'
import HowCanWeHelp from '../../components/HowCanWeHelp'
const Development = () => {
    return (
        <div className="itservices_container">
            <InternalHeader />
            <InternalDevelopment />
            <ContactUs />
            {/* <HowCanWeHelp /> */}
            <Footer />
        </div>
    )
}

export default Development
