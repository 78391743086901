import Footer from '../../components/Footer'
import HeroSection from '../../components/HeroSection'
import Header from '../../components/Header'
import InternalHeader from '../../components/InternalHeader'
import WhoAreWe from '../../components/WhoAreWe'
import WeProvideFeatures from '../../components/WeProvideFeatures'
import WhyChooseUs from '../../components/WhyChooseUs'
import ContactUs from '../../components/ContactUs'
import HowCanWeHelp from '../../components/HowCanWeHelp'
const Layout = () => {
    return (
        <div className="layout_container">
            <InternalHeader />
            <HeroSection/>
            {/* <Header /> */}
            <WhoAreWe /> 
            <WeProvideFeatures />
            <WhyChooseUs />
            <ContactUs />
            <HowCanWeHelp />
            <Footer />
        </div>
    )
}

export default Layout
