import '../styles/components/InternalITServices.scss'
import erp1 from '../assets/erp_services.jpg'
import erp2 from '../assets/odoo.jpg'
import erp3 from '../assets/salesforce.png'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'

const InternalERPServices = () => {
    const location = useLocation()
    const odooRef = useRef(null)
    const salesforceRef = useRef(null)

    useEffect(() => {
        if (location.state?.scrollTo === 'odoo' && odooRef.current) {
            odooRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'salesforce' && salesforceRef.current) {
            salesforceRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [location.state])

    return (
        <div className="internalITServicesConatiner">
            <img src={erp1} alt="" className="Itservice1"/>
            <h1> ERP Services </h1>
            <p>Streamline your business operations with our expert ERP solutions tailored for efficiency and growth.</p>
            <div className="internalITServicesConatiner_left" ref={odooRef}>
                <img src={erp2} alt="" className="Itservice2"/>
                <div>
                    <h1>Odoo</h1>
                    <p>Explore our comprehensive Odoo services, encompassing implementation, customization, integration, and support. We specialize in optimizing workflows and enhancing business efficiency through Odoo's powerful features in CRM, eCommerce, and more. Partner with us to tailor Odoo solutions that meet your unique organizational requirements and drive sustainable growth.</p>
                </div>
               
            </div>
            <div className="internalITServicesConatiner_right" ref={salesforceRef}>
                <div>
                    <h1>Salesforce</h1>
                    <p>Unlock the full potential of Salesforce with our tailored services including implementation, customization, integration, and support. We specialize in optimizing Salesforce CRM to streamline operations, enhance customer engagement, and drive business growth. Partner with us to harness Salesforce's powerful capabilities and achieve operational excellence efficiently.</p>
                </div>
                <img src={erp3} alt="" className="Itservice3"/>
            </div>
        </div>
    )
}

export default InternalERPServices
