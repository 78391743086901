import '../styles/components/InternalITServices.scss'
import quality1 from '../assets/qualityservices.jpg'
import quality2 from '../assets/consulting.png'
import quality3 from '../assets/wifi.jpg'
import quality4 from '../assets/automation.jpg'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'

const InternalQASolutions = () => {
    const location = useLocation()
    const consultingRef = useRef(null)
    const certificationWifiRef = useRef(null)
    const automationRef = useRef(null)
    
    useEffect(() => {
        if (location.state?.scrollTo === 'consulting' && consultingRef.current) {
            consultingRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'certificationWifi' && certificationWifiRef.current) {
            certificationWifiRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'automation' && automationRef.current) {
            automationRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [location.state])

    return (
        <div className="internalITServicesConatiner">
            <img src={quality1} alt="" className="Itservice1"/>
            <h1> Quality Services</h1>
            <p>Delivering excellence through rigorous standards and innovative solutions tailored to exceed client expectations.</p>
            <div className="internalITServicesConatiner_left" ref={consultingRef}>
                <img src={quality2} alt="" className="Itservice2"/>
                <div>
                    <h1>Consulting</h1>
                    <p>Unlock your business's potential with our expert consulting services. We provide strategic insights and customized solutions to help you navigate complex challenges and drive sustainable growth. Our team of seasoned professionals collaborates with you to identify opportunities, optimize processes, and implement effective strategies. Partner with us to achieve your business goals and stay ahead in a competitive market. Experience transformative results and elevate your success with our dedicated consulting expertise.</p>
                </div>
               
            </div>
            <div className="internalITServicesConatiner_right" ref={certificationWifiRef}>
                <div>
                    <h1>Certification</h1>
                    <p>Certification plays a crucial role in ensuring the reliability and compatibility of WiFi, video, and OTT services. Our certification services validate compliance with industry standards, ensuring optimal performance and user experience. Whether you're developing wireless technologies, video streaming solutions, or OTT applications, our expertise ensures that your products meet rigorous quality and regulatory requirements. Partner with us to streamline the certification process and accelerate your market readiness with confidence.</p>
                </div>
                <img src={quality3} alt="" className="Itservice3"/>
            </div>

            <div className="internalITServicesConatiner_left" ref={automationRef}>
                <img src={quality4} alt="" className="Itservice4"/>
                <div>
                    <h1>Automation</h1>
                    <p>Experience the future of efficiency with our advanced automation solutions. Streamline your processes, reduce manual tasks, and enhance productivity with technology designed to optimize your workflow. Our innovative automation tools integrate seamlessly into your operations, providing real-time insights and improving accuracy. Transform your business and achieve new levels of success with automation that drives growth, reduces costs, and empowers your team. Stay competitive in a rapidly evolving market and unlock the full potential of your business with our state-of-the-art automation services.</p>
                </div>
               
            </div>
        </div>
    )
}

export default InternalQASolutions
