import '../styles/components/WhoAreWe.scss';

const WhoAreWe = () => {
    return (
        <div className="WhoAreWeConatiner">
            <h1>Who We Are ?</h1>
            <p>
            At our core, we are dedicated to providing a comprehensive suite of technology services, all delivered with the utmost quality. Our innovative mindset allows us to address complex challenges through state-of-the-art technology solutions that are specifically tailored to our customers' unique needs. By thinking outside the box, we strive to exceed expectations and deliver exceptional value.
            </p>
        </div>
    );
};

export default WhoAreWe;
