// import "../styles/components/InternalHeader.scss";
// import CompanyLogo from "../assets/CompanyLogo.svg";
// import hamburger from "../assets/hamburger.png";
// import arrow from "../assets/arrow.svg";
// import { useNavigate } from "react-router-dom";
// import useAnalyticsEventTracker from "../utils/useAnalyticsEventTracker.jsx";
// import { useEffect, useState } from "react";

// const InternalHeader = () => {
//   const navigate = useNavigate();
//   const handleLogoClick = () => {
//     navigate("/");
//   };
//   const gaEventTracker = useAnalyticsEventTracker("header");

//   const [openDropdown, setOpenDropdown] = useState("");
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const handleDropdownClick = (dropdownName) => {
//     setOpenDropdown(openDropdown === dropdownName ? "" : dropdownName);
//   };

//   const handleHamburgerClick = () => {
//     setIsMenuOpen(!isMenuOpen); // Add this function
//   };

//   return (

//     <div className="HeaderContainer ">
//       <div className="HeaderContainer_image-container">
//         <img src={CompanyLogo} alt="companylogo" style={{ height: "100%" }} onClick={handleLogoClick} />
//       </div>
//       <div className={`HeaderContainer_list-container ${isMenuOpen ? "_open" : ""}`}>
//         <div className="HeaderContainer_list-container_list" onClick={() => handleDropdownClick('ITServices')}>          
//           IT Services
//           <img src={arrow} alt="arrow" />
//           {openDropdown === 'ITServices' && (
//                        <div className="dropdown">
//                        <div onClick={() => { navigate('/ITServices', { state: { scrollTo: 'staffing' } }); gaEventTracker('staffing') }}>
//                            Staffing
//                        </div>


                       
//                        <div onClick={() => { navigate('/ITServices', { state: { scrollTo: 'augmentation' } }); gaEventTracker('augmentation') }}>
//                            Augmentation
//                        </div>
//                        <div onClick={() => { navigate('/ITServices', { state: { scrollTo: 'recruitment' } }); gaEventTracker('recruitment') }}>
//                            Recruitment
//                        </div>
//                    </div>
//                     )}
//         </div>
//         <div className="HeaderContainer_list-container_list" onClick={() => handleDropdownClick('Development')}>          
//         SW Development
//           <img src={arrow} alt="arrow" />
//           {openDropdown === 'Development' && (
//                         <div className="dev-dropdown">
//                             <div onClick={() => { navigate('/Development', { state: { scrollTo: 'video' } }); gaEventTracker('video') }}>Video</div>
//                             <div onClick={() => { navigate('/Development', { state: { scrollTo: 'broadband' } }); gaEventTracker('broadband') }}>Broadband</div>
//                             <div onClick={() => { navigate('/Development', { state: { scrollTo: 'devOps' } }); gaEventTracker('devOps') }}>DevOps</div>
//                             <div onClick={() => { navigate('/Development', { state: { scrollTo: 'cloudComputing' } }); gaEventTracker('cloudComputing') }}>Cloud Computing</div>
//                             <div onClick={() => { navigate('/Development', { state: { scrollTo: 'fullStack' } }); gaEventTracker('fullStack') }}>Full Stack</div>
//                             <div onClick={() => { navigate('/Development', { state: { scrollTo: 'dataScience' } }); gaEventTracker('dataScience') }}>Data Science</div>
//                             <div onClick={() => { navigate('/Development', { state: { scrollTo: 'AiMl' } }); gaEventTracker('AiMl') }}>AI/ML</div>
//                             <div onClick={() => { navigate('/Development', { state: { scrollTo: 'mobileApps' } }); gaEventTracker('mobileApps') }}>Mobile Apps</div>
//                         </div>
//                     )}
//         </div>
//         <div className="HeaderContainer_list-container_list" onClick={() => handleDropdownClick('QualityServices')}>          
//         SW QA Solutions
//           <img src={arrow} alt="arrow" />
//           {openDropdown === 'QualityServices' && (
//                       <div className="quality-dropdown">
//                       <div onClick={() => { navigate('/QASolutions', { state: { scrollTo: 'consulting' } }); gaEventTracker('consulting') }}>
//                           Consulting
//                       </div>
                      
//                       <div onClick={() => { navigate('/QASolutions', { state: { scrollTo: 'certificationWifi' } }); gaEventTracker('certificationWifi') }}>
//                           Certification
//                       </div>
                      
//                       <div onClick={() => { navigate('/QASolutions', { state: { scrollTo: 'automation' } }); gaEventTracker('automation') }}>
//                           Automation
//                       </div>
//                   </div>
//                     )}
//         </div>
//         <div className="HeaderContainer_list-container_list" onClick={() => handleDropdownClick('ERPServices')}>          
//         ERP Services
//           <img src={arrow} alt="arrow" />
//           {openDropdown === 'ERPServices' && (
//                         <div className="erp-dropdown">
//                             <div onClick={() => { navigate('/ERPServices', { state: { scrollTo: 'odoo' } }); gaEventTracker('odoo') }}>Odoo</div>
//                             <div onClick={() => { navigate('/ERPServices', { state: { scrollTo: 'salesforce' } }); gaEventTracker('salesforce') }}>Salesforce</div>
//                         </div>
//                     )}
//         </div>
//         <button onClick={() => { navigate('/', { state: { scrollTo: 'howcanwehelp' } }); gaEventTracker('howcanwehelp') }}>Contact Us</button>            
//       </div>
//       <div className="HeaderContainer_hamburger" onClick={handleHamburgerClick}>
//       <img src={hamburger} alt="Hamburger Menu" />
//       </div> 
//     </div>
//   );
// };

// export default InternalHeader;




import "../styles/components/InternalHeader.scss";
import CompanyLogo from "../assets/CompanyLogo.svg";
import hamburger from "../assets/hamburger.png";
import arrow from "../assets/arrow.svg";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../utils/useAnalyticsEventTracker.jsx";
import { useState } from "react";

const InternalHeader = () => {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/");
  };
  const gaEventTracker = useAnalyticsEventTracker("header");

  const [openDropdown, setOpenDropdown] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openTechStacks, setOpenTechStacks] = useState(false);

  const handleDropdownClick = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? "" : dropdownName);
    if (dropdownName !== 'TechStacks') setOpenTechStacks(false); // Close tech stacks dropdown when another dropdown is clicked
  };

  const handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleTechStacksClick = () => {
    setOpenTechStacks(!openTechStacks);
  };

  return (
    <div className="HeaderContainer">
      <div className="HeaderContainer_image-container">
        <img src={CompanyLogo} alt="companylogo" style={{ height: "100%" }} onClick={handleLogoClick} />
      </div>
      <div className={`HeaderContainer_list-container ${isMenuOpen ? "_open" : ""}`}>
        <div className="HeaderContainer_list-container_list" onClick={() => handleDropdownClick('ITServices')}>
          IT Services
          <img src={arrow} alt="arrow" />
          {openDropdown === 'ITServices' && (
            <div className="dropdown">
              <div onClick={() => { navigate('/ITServices', { state: { scrollTo: 'staffing' } }); gaEventTracker('staffing') }}>Staffing</div>
              <div onClick={() => { navigate('/ITServices', { state: { scrollTo: 'augmentation' } }); gaEventTracker('augmentation') }}>Augmentation</div>
              <div onClick={() => { navigate('/ITServices', { state: { scrollTo: 'recruitment' } }); gaEventTracker('recruitment') }}>Recruitment</div>
            </div>
          )}
        </div>
        <div className="HeaderContainer_list-container_list" onClick={() => handleDropdownClick('Development')}>
          SW Development
          <img src={arrow} alt="arrow" />
          {openDropdown === 'Development' && (
            <div className="dev-dropdown">
              <div onClick={() => { navigate('/Development', { state: { scrollTo: 'video' } }); gaEventTracker('video') }}>Video</div>
              <div onClick={() => { navigate('/Development', { state: { scrollTo: 'broadband' } }); gaEventTracker('broadband') }}>Broadband</div>
              <div className="tech-stacks-dropdown" onClick={(e) => { e.stopPropagation(); handleTechStacksClick(); }}>
                Tech Stacks
                {openTechStacks && (
                  <div className="tech-stacks-submenu">
                    <div onClick={() => { navigate('/Development', { state: { scrollTo: 'devOps' } }); gaEventTracker('devOps') }}>DevOps</div>
                    <div onClick={() => { navigate('/Development', { state: { scrollTo: 'cloudComputing' } }); gaEventTracker('cloudComputing') }}>Cloud Computing</div>
                    <div onClick={() => { navigate('/Development', { state: { scrollTo: 'fullStack' } }); gaEventTracker('fullStack') }}>Full Stack</div>
                    <div onClick={() => { navigate('/Development', { state: { scrollTo: 'dataScience' } }); gaEventTracker('dataScience') }}>Data Science</div>
                    <div onClick={() => { navigate('/Development', { state: { scrollTo: 'AiMl' } }); gaEventTracker('AiMl') }}>AI/ML</div>
                    <div onClick={() => { navigate('/Development', { state: { scrollTo: 'mobileApps' } }); gaEventTracker('mobileApps') }}>Mobile Apps</div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="HeaderContainer_list-container_list" onClick={() => handleDropdownClick('QualityServices')}>
          SW QA Solutions
          <img src={arrow} alt="arrow" />
          {openDropdown === 'QualityServices' && (
            <div className="quality-dropdown">
              <div onClick={() => { navigate('/QASolutions', { state: { scrollTo: 'consulting' } }); gaEventTracker('consulting') }}>Consulting</div>
              <div onClick={() => { navigate('/QASolutions', { state: { scrollTo: 'certificationWifi' } }); gaEventTracker('certificationWifi') }}>Certification</div>
              <div onClick={() => { navigate('/QASolutions', { state: { scrollTo: 'automation' } }); gaEventTracker('automation') }}>Automation</div>
            </div>
          )}
        </div>
        <div className="HeaderContainer_list-container_list" onClick={() => handleDropdownClick('ERPServices')}>
          ERP Services
          <img src={arrow} alt="arrow" />
          {openDropdown === 'ERPServices' && (
            <div className="erp-dropdown">
              <div onClick={() => { navigate('/ERPServices', { state: { scrollTo: 'odoo' } }); gaEventTracker('odoo') }}>Odoo</div>
              <div onClick={() => { navigate('/ERPServices', { state: { scrollTo: 'salesforce' } }); gaEventTracker('salesforce') }}>Salesforce</div>
            </div>
          )}
        </div>
        <button onClick={() => { navigate('/', { state: { scrollTo: 'howcanwehelp' } }); gaEventTracker('howcanwehelp') }}>Contact Us</button>
      </div>
      <div className="HeaderContainer_hamburger" onClick={handleHamburgerClick}>
        <img src={hamburger} alt="Hamburger Menu" />
      </div>
    </div>
  );
};

export default InternalHeader;

