import '../styles/components/WeProvideFeatures.scss';
import feature1 from '../assets/itservices.jpg'
import feature2 from '../assets/swdevelopment.jpg'
import feature3 from '../assets/swqa.jpg'
import feature4 from '../assets/erp.jpg'

const WeProvideFeatures = () => {
    return (
        <div className="WeProvideFeatures">
            <div className="WeProvideFeaturesContainer">
                <h1>Explore Our Extensive Range of Features</h1>
                <p>
                We specialize in these areas to digitally empower our customers with personalized services.
                </p>
            </div>
            <div className="feature-section">
                <div className="feature-image">
                    <img src={feature1} alt="Feature" />
                </div>
                <div className="feature-content">
                    <h1>IT Services</h1>
                    <p>
                    We deliver comprehensive IT services to optimize and secure your digital infrastructure. Our expertise spans cloud computing, cybersecurity, and more, ensuring your business operates smoothly and efficiently.
                    </p>
                    <button className="know-more-button">Know More</button>
                </div>
            </div>
            <div className="feature2-section">
                <div className="feature2-content">
                    <h1>SW Development</h1>
                    <p>
                    Our software development services are tailored to meet your specific business needs, delivering innovative application development, user-friendly interfaces, and robust backend solutions to drive your digital growth.
                    </p>
                    <button className="know-more-button">Know More</button>
                </div>
                <div className="feature2-image">
                    <img src={feature2} alt="Feature" />
                </div>
            </div>
            <div className="feature3-section">
                <div className="feature3-image">
                    <img src={feature3} alt="Feature" />
                </div>
                <div className="feature3-content">
                    <h1>SW QA Solutions</h1>
                    <p>
                    Our software quality assurance services ensure seamless deployment of high-performance applications through comprehensive testing and rigorous quality control measures.
                    </p>
                    <button className="know-more-button">Know More</button>
                </div>
            </div>
            <div className="feature4-section">
                <div className="feature4-content">
                    <h1>ERP Services  </h1>
                    <p>
                    Our comprehensive ERP solutions streamline operations, enhance efficiency, and drive sustainable business growth through seamless implementation, customization, and support.
                    </p>
                    <button className="know-more-button">Know More</button>
                </div>
                <div className="feature4-image">
                    <img src={feature4} alt="Feature" />
                </div>
            </div>
        </div>
    );
};

export default WeProvideFeatures;
