import '../styles/components/WhyChooseUs.scss';
import whychooseusimg from '../assets/whychooseusimg.svg'
import bullet from '../assets/bullet.svg'


const WhyChooseUs = () => {
    return (
        <div className="WhyChooseUs">
            <div className="WhyChooseUsContainer">
                <div className="choose-section">
                    <div className="feature-left">
                        <div>why IntelliFirst ?</div>
                        <div className="feature-image">
                            <img src={whychooseusimg} alt="whychooseusimg" />
                        </div>
                    </div>
                    <div className="feature-right">
                    <img src={bullet} alt="bullet" />
                         <p>
                         Outcome-based performance
                        </p>
                        <img src={bullet} alt="bullet" />
                       <p>
                        Specialized industry focus
                        </p>
                        <img src={bullet} alt="bullet" />
                       <p>
                         Flexible engagement models
                        </p>
                        <img src={bullet} alt="bullet" />
                       <p>
                       Ability to address Quality from an organizational level or at the project level
                        </p>
                        <img src={bullet} alt="bullet" />
                        <p>
                         Global provider with abilities to scale up or down
                        </p>
                        <img src={bullet} alt="bullet" />
                         <p>
                         Move into Agile & DevOps environment
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
