import '../styles/components/ContactUs.scss';

const ContactUs = () => {
    return (
        <div className="ContactUs">
            <div className="ContactUscontent-left">
                <p>Curious to explore more about our services?</p>
            </div>    
            <div className="ContactUscontent-right">
                 <button>Contact Us Now</button>
            </div>
        </div>
    );
};

export default ContactUs;
