import { useEffect } from 'react'
import Layout from './pages/layout/Layout'
import ITServices from './pages/ITServices/ITServices'
import Development from './pages/Development/Development'
import QASolutions from './pages/QASolutions/QASolutions'
import ERPServices from './pages/ERPServices/ERPServices'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
function App() {
  const ScrollToTop = () => {
      const { pathname } = useLocation()

      useEffect(() => {
          window.scrollTo(0, 0)
      }, [pathname])

      return null
  }

  return (
      <>
          <Router>
              <ScrollToTop />
              <Routes>
                  <Route path="/" element={<Layout />} />
                  <Route path="/ITServices" element={<ITServices />} />
                  <Route path="/Development" element={<Development />} />
                  <Route path="/QASolutions" element={<QASolutions />} />
                  <Route path="/ERPServices" element={<ERPServices />} />
                 </Routes>
          </Router>
      </>
  )
}

export default App
