import '../styles/components/InternalITServices.scss'
import Itservice1 from '../assets/itservicesmain.jpg';
import Itservice2 from '../assets/staffing.jpg';
import Itservice3 from '../assets/augmentation.jpg';
import Itservice4 from '../assets/recruitment.jpg'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'

const InternalITServices = () => {
    const location = useLocation()
    const staffingRef = useRef(null)
    const augmentationRef = useRef(null)
    const recruitmentRef = useRef(null)

    useEffect(() => {
        if (location.state?.scrollTo === 'staffing' && staffingRef.current) {
            staffingRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'augmentation' && augmentationRef.current) {
            augmentationRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'recruitment' && recruitmentRef.current) {
            recruitmentRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [location.state])

    return (
        <div className="internalITServicesConatinerImg">
        <img src={Itservice1} alt="" className="Itservice1"/>
            
        <div className="internalITServicesConatiner">
            <h1>IT Services</h1>
            <p>Driving business success through tailored IT services and solutions,</p>
            <div className="internalITServicesConatiner_left"  ref={staffingRef}>
                <img src={Itservice2} alt="" className="Itservice2"/>
                <div>
                    <h2>Staffing</h2>
                    <p>Staffing is the strategic deployment of talent to meet organizational goals efficiently. At [Your Company Name], we specialize in providing tailored staffing solutions that align with your business objectives. Whether you need temporary support for a project or permanent placements to grow your team, we deliver comprehensive staffing services. Our expert recruiters ensure the right fit by assessing skills, experience, and cultural alignment. Partner with us to streamline your workforce management and achieve optimal productivity. Discover how our staffing solutions can elevate your business success today.</p>
                </div>
            </div>

            <div className="internalITServicesConatiner_right" ref={augmentationRef}>
                <div>
                    <h2>Augmentation</h2>
                    <p>Augmentation is more than just adding capacity—it's about leveraging cutting-edge technologies and expert insights to elevate your operations. Our augmentation services empower your team with specialized skills and resources, ensuring seamless integration and enhanced performance. Whether you're scaling up for a project or looking to optimize your existing capabilities, our tailored solutions deliver measurable results. Partner with us to unlock new efficiencies, drive innovation, and stay ahead in a competitive landscape.</p>
                </div>
                <img src={Itservice3} alt="" className="Itservice3"/>
            </div>

            <div className="internalITServicesConatiner_left"  ref={recruitmentRef}>
                <img src={Itservice4} alt="" className="Itservice4"/>
                <div>
                    <h2>Recruitment</h2>
                    <p>Recruitment is the cornerstone of building exceptional teams and driving organizational success. It involves identifying top talent, assessing skills, and ensuring cultural fit to meet business objectives effectively. Our comprehensive approach focuses on connecting qualified candidates with rewarding opportunities, fostering career growth and organizational development. Whether you're navigating the job market or seeking to enhance your team, trust our proven expertise to guide you toward your goals. Elevate your recruitment strategy and achieve sustainable success with our tailored solutions. Discover how strategic recruitment can transform your workforce and propel your business forward.</p>
                </div>
            </div>

        </div></div>
    )
}

export default InternalITServices
