import '../styles/components/Footer.scss'
import CompanyLogo from '../assets/footer-company-icon.svg'
import linkedin from '../assets/linkedin.svg'
import mail from '../assets/mail.svg'
import { useNavigate } from 'react-router-dom'
import useAnalyticsEventTracker from '../utils/useAnalyticsEventTracker.jsx'

const Footer = () => {
    const navigate = useNavigate()
    const handlePricing = () => {
        navigate('/price')
    }
    const handleInquiry = () => {
        navigate('/inquiry')
    }
    const gaEventTracker = useAnalyticsEventTracker('footer')
    return (
        <div className="footerContainer">
            <div className="footerContainer_footer">
                <div className="footerContainer_footer_first">
                    <div className="footerContainer_footer_first_part1">
                    <img src={CompanyLogo} alt=""/>
                    <div className="reachUs"><h4>Reach us online</h4></div><div className="reachUsImg"><img src={linkedin} alt=""/>
                    <a href="mailto:contactus@intellifirstsolutions.com">
                    <img src={mail} alt=""/></ a></div> 
                    
                        <h2
                            onClick={() => {
                                navigate('/')
                                gaEventTracker('/')
                            }}
                        >
                        </h2>
                    </div>
                    <div className="footerContainer_footer_first_part2">
                        <h2
                            onClick={() => {
                                gaEventTracker('ITservices')
                            }}
                        >
                            IT Services
                        </h2>
                        <h4
                            onClick={() => {
                                gaEventTracker('Staffing')
                            }}
                        >
                            Staffing 
                        </h4>
                        <h4
                            onClick={() => {
                                gaEventTracker('Recruitment')
                            }}
                        >
                            Recruitment
                        </h4>
                        <h4
                            onClick={() => {
                                gaEventTracker('Recruitment')
                            }}
                        >
                            Augmentation
                        </h4>
                    </div>
                     <div className="footerContainer_footer_first_part2">
                        <h2>SW Development</h2>
                        <h4>Video</h4>
                        <h4>Broadband</h4>
                        <h2>Tech Stack</h2>
                         <h4>DevOps </h4>
                         <h4>Cloud Computing </h4>
                        <h4>Full Stack</h4>
                        <h4>Data Science</h4>
                        <h4>AI/ML</h4>
                        <h4>Mobile Apps</h4>
                    </div> 
                    <div className="footerContainer_footer_first_part2">
                        <h2>SW QA Solutions</h2>
                        <h4>Consulting </h4>
                        <h4>Certification-Wifi, Video,OTT</h4>
                        <h4>Automation</h4>
                     </div> 
                    <div className="footerContainer_footer_first_part2">
                        <h2> ERP Services </h2>
                        <h4>Odoo </h4>
                        <h4>Salesforce</h4>
                    </div> 
                </div>
                        
            </div>
            <p>
                <span className="spanFooter">©</span>2024 Copyright: Technology Services
            </p>
        </div>
    )
}

export default Footer
