import Footer from '../../components/Footer'
import InternalHeader from '../../components/InternalHeader'
import InternalITServices from '../../components/InternalITServices'
import ContactUs from '../../components/ContactUs'
import HowCanWeHelp from '../../components/HowCanWeHelp'
const ITServices = () => {
    return (
        <div className="itservices_container">
            <InternalHeader />
            <InternalITServices />
            <ContactUs />
            {/* <HowCanWeHelp /> */}
            <Footer />
        </div>
    )
}

export default ITServices
