import '../styles/components/InternalITServices.scss'
import developmentservice from '../assets/developmentservice.jpg'
import devvideo from '../assets/video.jpg'
import devbrodband from '../assets/broadband.jpg'
import devdevops from '../assets/devops.jpg'
import devcloudcomputing from '../assets/cloud.jpg'
import devfulstack from '../assets/fullstack.jpg'
import devdatascience from '../assets/datascience.jpg'
import devai from '../assets/ai.jpg'
import devmobile from '../assets/mobile.jpg'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'

const InternalDevelopment = () => {
    const location = useLocation()
    const videoRef = useRef(null)
    const broadbandRef = useRef(null)
    const devOpsRef = useRef(null)
    const cloudComputingRef = useRef(null)
    const dataScienceRef = useRef(null)
    const AiMlRef = useRef(null)
    const mobileAppsRef = useRef(null)
    const fullStackRef = useRef(null)

    useEffect(() => {
        if (location.state?.scrollTo === 'video' && videoRef.current) {
            videoRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'broadband' && broadbandRef.current) {
            broadbandRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'devOps' && devOpsRef.current) {
            devOpsRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'cloudComputing' && cloudComputingRef.current) {
            cloudComputingRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'fullStack' && fullStackRef.current) {
            fullStackRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'dataScience' && dataScienceRef.current) {
            dataScienceRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'AiMl' && AiMlRef.current) {
            AiMlRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (location.state?.scrollTo === 'mobileApps' && mobileAppsRef.current) {
            mobileAppsRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [location.state])

    return (
        <div className="internalITServicesConatiner">
            <img src={developmentservice} alt="" className="Itservice1"/>
            <h1>Development</h1>
            <p>Empower Your Growth Through Expert Development Services</p>
            <div className="internalITServicesConatiner_left"  ref={videoRef}>
                <img src={devvideo} alt="" className="Itservice2"/>
                <div>
                    <h1>Video</h1>
                    <p>Explore our comprehensive video solutions designed to enhance your content creation and distribution. We specialize in high-quality video production, seamless streaming, and robust video management systems. Whether you need live streaming, on-demand services, or custom video platforms, our expertise ensures exceptional performance and user experience. Partner with us to leverage the power of video and engage your audience effectively. Transform your video strategy with innovative solutions tailored to your specific needs.</p>
                </div>
            </div>
            <div className="internalITServicesConatiner_right" ref={broadbandRef}>
                <div>
                    <h1>Broadband</h1>
                    <p>Experience our cutting-edge broadband solutions designed to provide reliable, high-speed internet connectivity. We specialize in delivering seamless, scalable, and robust broadband services to meet the demands of both residential and commercial users. Whether you need fiber-optic, DSL, or wireless broadband, our solutions ensure optimal performance and minimal downtime. Partner with us to enhance your connectivity, improve communication, and support your digital needs effectively.</p>
                </div>
                <img src={devbrodband} alt="" className="Itservice3"/>
            </div>

            <div className="internalITServicesConatiner_left"  ref={devOpsRef}>
                <img src={devdevops} alt="" className="Itservice2"/>
                <div>
                    <h1>DevOps</h1>
                    <p>Discover streamlined DevOps solutions designed to optimize your software development and deployment processes. Leveraging cutting-edge technologies, we specialize in seamless integration, automation, and continuous delivery. Enhance collaboration across teams and ensure high-quality releases with our expert DevOps services. Transform your IT infrastructure with scalable, reliable, and innovative DevOps practices. Partner with us to accelerate your development lifecycle and achieve greater efficiency and agility in your operations.</p>
                </div>
               
            </div>
            <div className="internalITServicesConatiner_right"  ref={cloudComputingRef}>
                <div>
                    <h1>Cloud Computing</h1>
                    <p>Our cloud computing solutions are designed to empower your business with scalable infrastructure, seamless migration strategies, and expert managed services. Whether you're looking to optimize costs, enhance agility, or improve scalability, we offer tailored solutions to meet your specific needs. Partner with us to leverage cutting-edge cloud technologies and accelerate your digital transformation securely and efficiently.</p>
                </div>
                <img src={devcloudcomputing} alt="" className="Itservice3"/>
            </div>
            <div className="internalITServicesConatiner_left"  ref={fullStackRef}>
                <img src={devfulstack} alt="" className="Itservice2"/>
                <div>
                    <h1>Full Stack</h1>
                    <p>Our full stack development services encompass frontend, backend, and database expertise, delivering cohesive and scalable solutions tailored to your business needs. With a focus on seamless integration and optimal performance, we empower businesses to innovate and thrive in the digital landscape. Partner with us to leverage our comprehensive skill set and drive your projects from conception to completion efficiently and effectively.</p>
                </div>
               
            </div>
            <div className="internalITServicesConatiner_right"  ref={dataScienceRef}>
                <div>
                    <h1>Data Science</h1>
                    <p>Our data science solutions leverage cutting-edge technologies in machine learning, statistical analysis, and predictive modeling to extract actionable insights from your data. We specialize in transforming complex data into valuable business intelligence, empowering you to make informed decisions and drive innovation. Partner with us to optimize operations, enhance customer experiences, and achieve sustainable growth through data-driven strategies.</p>
                </div>
                <img src={devdatascience} alt="" className="Itservice3"/>
            </div>
            <div className="internalITServicesConatiner_left"  ref={AiMlRef}>
                <img src={devai} alt="" className="Itservice2"/>
                <div>
                    <h1>AI/ML</h1>
                    <p>Our AI/ML solutions integrate cutting-edge artificial intelligence and machine learning technologies to automate processes, predict outcomes, and optimize decision-making. We specialize in developing intelligent systems that enhance operational efficiency and drive innovation across industries. Partner with us to leverage advanced AI/ML capabilities and transform your business with data-driven insights and predictive analytics.</p>
                </div>
               
            </div>
            <div className="internalITServicesConatiner_right"  ref={mobileAppsRef}>
                <div>
                    <h1>Mobile Apps</h1>
                    <p>Specializing in custom mobile app solutions for iOS and Android, we ensure intuitive interfaces and robust backend integration. Our focus on innovation and quality drives seamless user experiences, making us your ideal partner for impactful mobile solutions. From initial concept to final deployment, we provide end-to-end support to bring your app ideas to life and ensure they resonate with your audience effectively.</p>
                </div>
                <img src={devmobile} alt="" className="Itservice3"/>
            </div>
        </div>
    )
}

export default InternalDevelopment
